"use client";

import { useEffect, useMemo } from "react";
import { usePathname, useRouter } from "next/navigation";

export default function NotFound() {
  const pathname = usePathname();
  const router = useRouter();

  const route = useMemo(() => {
    return pathname
      ? pathname.substring(pathname.lastIndexOf("/") + 1)
      : "";
  }, [pathname]);

  useEffect(() => {
    if (route) {
      router.replace(`/en/${route}`);
    }
  }, [route, router]);

  return null;
}
